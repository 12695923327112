$('.autoresize').on('keyup', (e) => {
    const $this = $(e.currentTarget)
    const value = $this.val() || '';
    const numberOfLineBreaks = (value.match(/\n/g) || []).length;
    $this.attr('rows', 2 + numberOfLineBreaks);
})

$('[data-copy]').on('click', e => {
    e.preventDefault();
    const $this = $(e.currentTarget);

    navigator.clipboard.writeText($this.data('copy'));

    const $svg = $this.find('svg');

    if($svg.length) {
        const from = $svg.prop('outerHTML')
        const to = $('#tpl-copy-ok').html()

        $this.find('svg').replaceWith(to)

        setTimeout(() => {
            $this.find('svg').replaceWith(from)
        }, 1000);
    }

});

$('.scroll-to').on('click', (e) => {
    const target = $(e.currentTarget).data('target');
    const y = target ? ($(target).offset().top || 0) : 0;
    window.scrollTo(0, y);
});
